import React, {useState, useEffect} from 'react';
import ReactTooltip from "react-tooltip";
import './Table.css'
import LineTable from './LineTable/LineTable';
import {dateNumConvert, getWeekNumber} from "../Helpers/Date";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import {dataTypes, getLocal, setLocal} from "../Helpers/LocalStorage";
import StartSearch from "../StartSearch/StartSearch";
import {helperRedirect} from "../Helpers/Redirect";
import {API_URL} from "../../App";
import {useWindowSize} from "../Helpers/WindowsDimension";
import {get, set} from "idb-keyval";
import {typeSwipe, Swipe} from "../Helpers/Swipe";

const weekDaysData = [
    {weekDay: 'ПН'},
    {weekDay: 'ВТ'},
    {weekDay: 'СР'},
    {weekDay: 'ЧТ'},
    {weekDay: 'ПТ'},
    {weekDay: 'СБ'},
    {weekDay: 'ВС'},
]
const dayOfWeek = [6, 0, 1, 2, 3, 4, 5]

export default function Table(props) {
    const size = useWindowSize();

    const isWeekTable = size.width > 500;
    const scheduleType = props.group;
    //let isWeekTable = props.isWeekTable;
    let [schedule, setSchedule] = useState({'item': undefined, 'entity': undefined});
    const [reloadTable, setReloadTable] = useState(false);
    let isDefault;
    const today = new Date();

    const [date, setDate] = useState(new Date());
    const [style_helper, setStyle] = useState({});
    date.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    switch (scheduleType) {
        case dataTypes.DefaultSchedule:
            schedule = getLocal(dataTypes.DefaultSchedule);
            isDefault = false;
            break;
        case dataTypes.SelectedSchedule:
            schedule = getLocal(dataTypes.SelectedSchedule);
            isDefault = true;
            break;
        default:
            //TODO add default segment
            isDefault = false;
            break;
    }
    let showSearch = !schedule || schedule.item === undefined || schedule.item.length === 0 || schedule.entity === undefined || schedule.entity.length === 0;


    const weekDays = [];
    const dayWeekIndex = dayOfWeek[date.getDay()];
    const odd = getWeekNumber(date) % 2 ? 1 : 0;
    const weekStart = new Date(date);

    weekStart.setDate(date.getDate() - dayOfWeek[date.getDay()]);

    let style_val = {

    };

    //TODO add color change on weekday
    //weekDays.push(<div className='time'></div> );
    if (isWeekTable) {
        weekDaysData.slice(0, 6).forEach((day, index) => {
            weekDays.push(
                <section key={'week-day-wrapper-' + index}
                         className={'week-day-wrapper' + ((date.getTime() === today.getTime() && index === dayWeekIndex) ? ' select' : '')}>
                    <h2 className='week-day'>{day.weekDay},{'\u00A0'}{dateNumConvert(weekStart)}</h2>
                </section>
            );
            weekStart.setDate(weekStart.getDate() + 1);
        });
    } else {
        weekDays.push(
            <section key={'week-day-wrapper-' + dayWeekIndex}
                     className={'week-day-wrapper' + ((date.getTime() === today.getTime()) ? ' select' : '')}>
                <h2 className='week-day'>{weekDaysData[dayWeekIndex].weekDay},{'\u00A0'}{dateNumConvert(date)}</h2>
            </section>
        );
    }

    const increment = isWeekTable ? 7 : 1;
    function handleChangeForward(event) {
        // We invoke the callback with the new value
        style_val = {
            display: "grid",
            right: "0%"
        };
        setStyle(style_val);

        style_val = {
            display: "grid",
            right: "30%",
            opacity: "0.2"
        };

        setTimeout(() => {
            setStyle(style_val);
            let newValue = new Date(date);
            newValue.setDate(newValue.getDate() + increment);
            setDate(newValue);
            style_val = {
                display: "none",
                right: "0"
            };
            setTimeout(() => {setStyle(style_val)}, 180);
        }, 50);

    }

    function handleChangeBackward(event) {
        // We invoke the callback with the new value
        style_val = {
            display: "grid",
            left: "0%"
        };
        setStyle(style_val);

        style_val = {
            display: "grid",
            left: "30%",
            opacity: "0.2"
        };

        setTimeout(() => {
            setStyle(style_val);
            let newValue = new Date(date);
            newValue.setDate(newValue.getDate() - increment);
            setDate(newValue);
            style_val = {
                display: "none",
                left: "0"
            };
            setTimeout(() => {setStyle(style_val)}, 180);
        }, 50);
        //setTimeout(() => {setStyle(style_val)}, 200);

    }

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedOld, setIsLoadedOld] = useState(false);
    const [info, setInfo] = useState(undefined);
    const [data, setData] = useState({'items': [], 'entity': undefined});
    const [week, setWeek] = useState(undefined);
    useEffect(() => {
        if (!showSearch) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Accept-Encoding': 'gzip, deflate, br',

                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify({
                    "query": schedule.item,
                    "req_type": "table",
                    'data_type': schedule.entity,
                    'req_act': 'data',
                }),
            };
            fetch(API_URL, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        set(scheduleType, result);
                        setData(result.data);
                        setInfo(result.info)
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        }
    }, [reloadTable]);


    useEffect(() => {
        get(scheduleType).then(value => {
            if (value) {
                setIsLoadedOld(true);
                setData(value.data);
                setInfo(value.info);
            }
        });
    }, []);


    useEffect(() => {
        setWeek(data?.items.filter(value => {
            return value.Even === odd;
        }));
    }, [date, data])


    function handleSearch(data) {
        setLocal(dataTypes.SelectedSchedule, data);

        setSchedule(data);
        setReloadTable(true);

        if (showSearch) {
            isDefault = true;
            setLocal(dataTypes.DefaultSchedule, getLocal(dataTypes.SelectedSchedule));
            helperRedirect('/mySchedule');
        } else {
            helperRedirect('/schedule');
        }
    }

    if (showSearch) {
        return <StartSearch onSelect={handleSearch}  type={'table'}/>;
    }

    const isTeacher = schedule.entity === 'teacher';
    const h1 = isTeacher ? schedule.item + '\u00A0' : 'Группа ' + schedule.item + '\u00A0';
    const h2 = info;

    const text1 = 'Нажмите, чтобы сохранить в раздел «Мое расписание»';
    const text2 = 'Расписание уже было сохранено, но Вы всегда можете найти другое';

    const icon = <i className="material-icons" id="header-icon" data-tip={isDefault ? text1 : text2}

                    onClick={handleSetDefaultSchedule}>{isDefault ? ' radio_button_unchecked' : 'radio_button_checked'}</i>;


    function handleSetDefaultSchedule(event) {
        setLocal(dataTypes.DefaultSchedule, getLocal(dataTypes.SelectedSchedule));
        helperRedirect('/mySchedule');
    }

    function handleOnSwipe(event){
        switch (event) {
            case typeSwipe.onSwipedRight:
                handleChangeBackward();
                break;
            case typeSwipe.onSwipedLeft:
                handleChangeForward();
                break;
            default:
                break;
        }
    }


    let header = <Header H1={h1} H2={h2} Icon={icon} onSelect={handleSearch}
                         showSearch={true} type={'table'} />;

    let table_body = <table className='schedule-week'>
        <tbody>
        <tr>
            <LineTable json={week} time={'8:00'} isWeekTable={isWeekTable} dayWeekIndex={dayWeekIndex}
                       isTeacher={isTeacher}/>
        </tr>
        <tr>
            <LineTable json={week} time={'9:40'} isWeekTable={isWeekTable} dayWeekIndex={dayWeekIndex}
                       isTeacher={isTeacher}/>
        </tr>
        <tr>
            <LineTable json={week} time={'11:35'} isWeekTable={isWeekTable} dayWeekIndex={dayWeekIndex}
                       isTeacher={isTeacher}/>
        </tr>
        <tr>
            <LineTable json={week} time={'13:45'} isWeekTable={isWeekTable} dayWeekIndex={dayWeekIndex}
                       isTeacher={isTeacher}/>
        </tr>
        <tr>
            <LineTable json={week} time={'15:40'} isWeekTable={isWeekTable} dayWeekIndex={dayWeekIndex}
                       isTeacher={isTeacher}/>
        </tr>
        <tr>
            <LineTable json={week} time={'17:20'} isWeekTable={isWeekTable} dayWeekIndex={dayWeekIndex}
                       isTeacher={isTeacher}/>
        </tr>
        <tr>
            <LineTable json={week} time={'19:00'} isWeekTable={isWeekTable} dayWeekIndex={dayWeekIndex}
                       isTeacher={isTeacher}/>
        </tr>
        <tr>
            <LineTable json={week} time={'20:35'} isWeekTable={isWeekTable} dayWeekIndex={dayWeekIndex}
                       isTeacher={isTeacher}/>
        </tr>
        </tbody>
    </table>;

    let table_part = <Swipe className='background-table' onSwipe={handleOnSwipe} length={10}>
        <div className='week-days-wrapper'>
            <section className='week-days'>
                {weekDays}
            </section>
            <p className='underline-week-days'/>
        </div>
        {table_body}
        <Footer selectedDate={date} onForward={handleChangeForward} onBackward={handleChangeBackward} isWeekTable={isWeekTable}/>
    </Swipe>;

    let table_helper = <div className='background-table helper-table' style = {style_helper}>
        <div className='week-days-wrapper'>
            <section className='week-days'>
                {weekDays}
            </section>
            <p className='underline-week-days'/>
        </div>
        {table_body}
        <Footer selectedDate={date} onForward={handleChangeForward} onBackward={handleChangeBackward} isWeekTable={isWeekTable}/>
    </div>;

    let table = <>
        {table_part}
        {table_helper}
        </>;


    /*let content =
        showSearch ?
            <div id="search-form" className="search-main-wrapper">
                <Search onSelect={handleSearch}/>
            </div> :
            <div className={'grid-content'}>
                {header}
                {<div className='table-wrapper'>
                    {table}
                </div>}
            </div>;*/
    let content =
        <div className={'grid-content'}>
            {header}
            <ReactTooltip className={"tooltip"} place="bottom" type={"dark"}/>
            {<div className='table-wrapper'>
                {table}
            </div>}
        </div>;


    if (error) {
        console.log('Ошибка: ' + error.message);
    }

    if ((!isLoadedOld && !isLoaded) || data === undefined) {
        return null;
    } else {
        return content;
    }
}