export function ShortenName(teacherStr) {
    let result = '';
    let splitterName = teacherStr.split(' ');
    splitterName.map((value, index) => result += (index === 0 || is_numeric(value[0]) ? value + ' ' : value[0] + '.'));
    return result;
}


function is_numeric(str) {
    return /^\d+$/.test(str);
}