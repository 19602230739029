import React, {useEffect} from "react";
import {
    useLocation,
    Switch,
    Route, Redirect
} from "react-router-dom";

import Table from "./Component/Table/Table";
import {helperRedirect, setHistory} from './Component/Helpers/Redirect';
import LkFrame from "./Component/LkFrame/LkFrame";
import {dataTypes} from "./Component/Helpers/LocalStorage";

import TeacherList from "./Component/TeacherList/TeacherList";
import MenuWrapper from "./Component/Menu/MenuWrapper";
import './fonts/MaterialIcons.woff2'
import './App.css';
import './Component/Helpers/Colors.css'
import HelmetWrapper from "./Component/HelmetWrapper/HelmetWrapper";

//export const API_URL = `${window.location.protocol}//${window.location.hostname}:8080/api/v1/`;

export const API_URL = `${window.location.protocol}//${window.location.host}/api/v1/`;


function App() {
    //console.log(document.documentElement.clientWidth + 'x' + document.documentElement.clientHeight);

    const location = useLocation();
    useEffect(() => {
        //document.documentElement.style.setProperty('--vh', `${window.innerHeight* 0.01}px`);
        if (location.pathname === '/' || location.pathname === '')
            helperRedirect('/mySchedule');
    }, []);

    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

    return (
        <div className="App">
            <HelmetWrapper/>
            <div className='grid-app'>
                <MenuWrapper/>
                <div className='content-wrapper'>
                    <Route component={HistorySetter}/>
                    <Switch>
                        <Route path='/schedule' component={() => <Table group={dataTypes.SelectedSchedule}/>}/>
                        <Route path='/mySchedule' component={() => <Table group={dataTypes.DefaultSchedule}/>}/>
                        <Route path='/map' component={null}/>
                        <Route path='/lk' component={() => <LkFrame/>}/>
                        <Route path='/listOfTeachers' component={() => <TeacherList/>}/>
                        <Route>
                            <Redirect to='/mySchedule'/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

function HistorySetter({history}) {
    setHistory(history); //Прокидывем history в хэлпер
    return null;
}

export default App;
