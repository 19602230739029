import React from 'react';
import './Header.css'
import Search from "../Search/Search";
import {ShortenName} from "../Helpers/ShortenName";

export default function Header(props) {

    const showSearch = props.showSearch;
    const H1 = FullName(props.H1);
    const H2 = props.H2;
    const icon = props.Icon;

    let header = <>
        <header>
            <section className="group-section">
                <h1 className="group">{H1}{icon}</h1>
                <div className='h2-search-wrapper'>
                    <div className='h2_container'>
                        <h2 className="faculty">{H2}</h2>
                        <p className="underline-group"/>
                    </div>
                    {showSearch &&
                    <div className="search-form">
                        <Search onSelect={props.onSelect} type={props.type} />
                    </div>}
                </div>
            </section>
        </header>
    </>;

    return (header);
}

function FullName(fullName) {
    return document.documentElement.clientWidth > 500 || fullName.indexOf("ЛЭТИ") !== -1 ? fullName : ShortenName(fullName);
}