import React, {useEffect, useState} from "react";
import Header from "../Header/Header";
import TeacherCard from "./TeacherCard/TeacherCard";
import './TeacherList.css';
import {API_URL} from "../../App";
import {set, get} from "idb-keyval";
import {teachersList} from "./TeachersData";
const teachersData = require("../../Teachers.json");

export default function TeacherList(props) {

    let [search, setSearch] = useState({'item': undefined, 'entity': undefined});
    const key = 'TeacherData';
    const [info, setInfo] = useState(undefined);
    const [data, setData] = useState({'items': [], 'entity': undefined});
    let arrayForHide = Array(55).fill(true);
    const [hideList, setHide] = useState(arrayForHide);
    const [showSearchResult, setSearchResult] = useState(true);


    useEffect(()=>{

        get(key).then(value => {
            if(value){
            setData(value.data);
            setInfo(value.info);}
        });
    },[]);

    function handleSearch(data) {
        setSearch(data);
    }

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        if (search === undefined || search.item === undefined || search.item.length === 0 || search.entity === undefined || search.entity.length === 0) {
            return;
        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Accept-Encoding': 'gzip, deflate, br',

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({
                'query': search.item,
                'req_type': 'teachers',
                'data_type': search.entity,
                'req_act': 'data',
            }),
        };
        fetch(API_URL, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    set(key, result)
                    setData(result.data);
                    setInfo(result.info);
                    setSearchResult(true);
                    console.log(data);
                    console.log(data.items);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    }, [search])
    const showSearch = !data || !data.items || data.items.length === 0;

    function listOnClick(index)
    {
        arrayForHide = hideList.slice();
        arrayForHide[index] = !arrayForHide[index];
        setHide(arrayForHide);
    }

    function showSearchCard(val)
    {
        setSearchResult(!showSearchResult);
    }

    function teachersParser(shortname)
    {
        return teachersData.filter((item) => {return item["Department"] === shortname});
    }

    const content = <>
       <Header H1={'СПбГЭТУ "ЛЭТИ"'} H2={'Список преподавателей'} onSelect={handleSearch}
               showSearch={true} type={'teachers'}/>
        <div className='table-wrapper teacher-wrapper' id="scrollbar">
            {data.items.length !== 0 && showSearchResult ?
            <TeacherCard data = {data.items[0]} showSearch = {true} className = {"search-card"} onclick = {showSearchCard}/> : null}
            <ul className={"faculty-list"}>{teachersList.map((value) =>
                <li>
                    <h3 className='faculty-li' onClick={() => {listOnClick(value.id)}}>{value.name}</h3>
                    <ul className={"department-list"} id = {hideList[value.id]? "hide-list": null}>
                        {value.departments!=null ? value.departments.map((departmentVal) =>
                            <li className={"department-li"} >
                                <h3 className={"department-li-title"} onClick={() => {listOnClick(departmentVal.id)}}>{departmentVal.name}</h3>
                                <div className={"teachers-card-container"} id = {hideList[departmentVal.id]? "hide-list": null}>
                                    {
                                        teachersParser(departmentVal.shortName).map((item) =>
                                            <TeacherCard data = {item} showSearch = {false}/>
                                        )
                                    }
                                </div>
                            </li>)
                            : <div className={"teachers-card-container"} id = {hideList[value.id]? "hide-list": null}>
                                {
                                    teachersParser(value.shortName).map((item) =>
                                        <TeacherCard data = {item} showSearch = {false}/>
                                    )
                                }
                            </div>
                        }
                    </ul>
                </li>)}
            </ul>
        </div>
   </>;

    /*const content = <>
        <Header H1={'СПбГЭТУ "ЛЭТИ"'} H2={'Список преподавателей'} onSelect={handleSearch}
                showSearch={true} type={'teachers'}/>
        <div className='table-wrapper' id="scrollbar">
            <div className='rows-container'>
                {data.items.map((value) => <TeacherCard data={value}/>)}
            </div>
        </div>
    </>;*/

    return content;
}