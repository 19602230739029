import React, {useState, useEffect, useRef} from 'react';
import isAndroid from "./IsAndroid";

export default function OnKeyboardEvent(props) {
    const [isHidden, setIsHidden] = useState(false);

    function onFocusEvent(event) {
        const target = event.target;
        switch (target.tagName) {
            case "INPUT":
            case "TEXTAREA":
            case "SELECT":
                //document.body.classList.add("keyboard");
                setIsHidden(true);
                break;
        }
    }

    function onBlurEvent(event) {
        setIsHidden(false);
    }

    useEffect(() => {
        if (!isAndroid())
            return;
        document.body.addEventListener("focus", onFocusEvent, true);
        document.body.addEventListener("blur", onBlurEvent, true);

        return () => {
            document.body.removeEventListener("focus", onFocusEvent, true);
            document.body.removeEventListener("blur", onBlurEvent, true);
        }
    }, []);

    return <div hidden={isHidden}>{props.children}</div>
}