import React from "react";
import './LkFrame.css'
import Header from "../Header/Header";

export default function LkFrame(props) {
    return (<>
            <div className='lk-wrapper'>
                <iframe className="inlineFrame" frameBorder="0"
                        width='100%'
                        height='100%'
                        title="Личный кабинет"
                        src="https://lk.etu.ru/login">
                </iframe>
            </div>
        </>
    )
}