export const teachersList = [
    {
        name: "Институт фундаментального инженерного образования (ИФИО)",
        departments: [
            {
                name: "Кафедра высшей математики (ВМ)",
                id: 1,
                shortName: "ВМ"
            },
            {
                name: "Кафедра физики (Ф)",
                id: 2,
                shortName: "Ф"
            },
            {
                name: "Кафедра физической химии (ФХ)",
                id: 3,
                shortName: "ФХ"
            },
            {
                name: "Кафедра прикладной механики и инженерной графики (ПМИГ)",
                id: 4,
                shortName: "ПМИГ"
            },
            {
                name: "Кафедра теоретических основ электротехники (ТОЭ)",
                id: 5,
                shortName: "ТОЭ"
            },
            {
                name: "Кафедра физического воспитания и спорта (ФВ и С)",
                id: 6,
                shortName: "ФВ и С"
            }],
        id: 0
    },
    {
        name: "Институт инновационного проектирования и технологического предпринимательства (ИНПРОТЕХ)",
        departments: [
            {
                name: "Кафедра менеджмента и систем качества (МСК)",
                id: 8,
                shortName: "МСК"
            },
            {
                name: "Кафедра инновационного менеджмента (ИМ)",
                id: 9,
                shortName: "ИМ"
            },
            {
                name: "Кафедра прикладной экономики (ПЭ)",
                id: 10,
                shortName: "ПЭ"
            },
            {
                name: "Кафедра экономической теории (ЭТ)",
                id: 11,
                shortName: "ЭТ"
            }],
        id: 7
    },
    {
        name: "Научно-Исследовательский Институт",
        departments: [
            {
                name: "Научно-Исследовательский Конструкторско-Технологический Институт Биотехнических Систем (НИКТИ БТС)",
                id: 13,
                shortName: "НИКТИ БТС"
            }],
        id: 12
    },
    {
        name: "Факультет радиотехники и телекоммуникаций (ФРТ)",
        departments: [
            {
                name: "Кафедра радиотехнических систем (РС)",
                id: 15,
                shortName: "РС"
            },
            {
                name: "Кафедра радиоэлектронных средств (РЭС)",
                id: 16,
                shortName: "РЭС"
            },
            {
                name: "Кафедра телевидения и видеотехники (ТВ)",
                id: 17,
                shortName: "ТВ"
            },
            {
                name: "Кафедра теоретических основ радиотехники (ТОР)",
                id: 18,
                shortName: "ТОР"
            },
            {
                name: "Кафедра микрорадиоэлектроники и технологии радиоаппаратуры (МИТ)",
                id: 19,
                shortName: "МИТ"
            },
            {
                name: "Базовая кафедра специальных средств радиоэлектроники (ССР)",
                id: 20,
                shortName: "ССР"
            },
            {
                name: "Базовая кафедра радиоэлектронных информационных систем и комплексов (РИСК)",
                id: 21,
                shortName: "РИСК"
            },
            {
                name: "Базовая кафедра радиоастрономии (РА)",
                id: 22,
                shortName: "РА"
            },
            {
                name: "Базовая кафедра нанотехнологий и наноматериалов в радиоэлектронике (ННР)",
                id: 23,
                shortName: "ННР"
            },
            {
                name: "Базовая кафедра видеоинформационных систем (ВИНС)",
                id: 24,
                shortName: "ВИНС"
            }],
        id: 14
    },
    {
        name: "Факультет электроники (ФЭЛ)",
        departments: [
            {
                name: "Кафедра радиотехнической электроники (РТЭ)",
                id: 26,
                shortName: "РТЭ"
            },
            {
                name: "Кафедра электронных приборов и устройств (ЭПУ)",
                id: 27,
                shortName: "ЭПУ"
            },
            {
                name: "Кафедра физической электроники и технологии (ФЭТ)",
                id: 28,
                shortName: "ФЭТ"
            },
            {
                name: "Кафедра фотоники (Фот)",
                id: 29,
                shortName: "Фот"
            },
            {
                name: "Кафедра микро- и наноэлектроники (МНЭ)",
                id: 30,
                shortName: "МНЭ"
            },
            {
                name: "Базовая кафедра оптоэлектроники (ОЭ)",
                id: 31,
                shortName: "ОЭ"
            },
            {
                name: "Кафедра электронного приборостроения (ЭП)",
                id: 32,
                shortName: "ЭП"
            }],
        id: 25
    },
    {
        name: "Факультет компьютерных технологий и информатики (ФКТИ)",
        departments: [
            {
                name: "Кафедра систем автоматизированного проектирования (САПР)",
                id: 34,
                shortName: "САПР"
            },
            {
                name: "Кафедра автоматики и процессов управления (АПУ)",
                id: 35,
                shortName: "АПУ"
            },
            {
                name: "Кафедра информационных систем (ИС)",
                id: 36,
                shortName: "ИС"
            },
            {
                name: "Кафедра математического обеспечения и применения ЭВМ (МО ЭВМ)",
                id: 37,
                shortName: "МО ЭВМ"
            },
            {
                name: "Кафедра вычислительной техники (ВТ)",
                id: 38,
                shortName: "ВТ"
            },
            {
                name: "Кафедра информационной безопасности (ИБ)",
                id: 39,
                shortName: "ИБ"
            },
            {
                name: "Кафедра алгоритмической математики (АМ)",
                id: 40,
                shortName: "АМ"
            },
            {
                name: "Базовая кафедра автоматизации исследований (АИ)",
                id: 41,
                shortName: "АИ"
            },
            {
                name: "Базовая кафедра интеллектуальных информационных технологий (ИИТ)",
                id: 42,
                shortName: "ИИТ"
            }],
        id: 33
    },
    {
        name: "Факультет электротехники и автоматики (ФЭА)",
        departments: [
            {
                name: "Кафедра систем автоматического управления (САУ)",
                id: 44,
                shortName: "САУ"
            },
            {
                name: "Кафедра электротехнологической и преобразовательной техники (ЭТПТ)",
                id: 45,
                shortName: "ЭТПТ"
            },
            {
                name: "Кафедра робототехники и автоматизации производственных систем (РАПС)",
                id: 46,
                shortName: "РАПС"
            },
            {
                name: "Кафедра корабельных систем управления (КСУ)",
                id: 47,
                shortName: "КСУ"
            }],
        id: 43
    },
    {
        name: "Гуманитарный факультет (ГФ)",
        departments: [
            {
                name: "Кафедра связи с общественностью (СО)",
                id: 49,
                shortName: "СО"
            },
            {
                name: "Кафедра философии (ФЛ)",
                id: 50,
                shortName: "ФЛ"
            },
            {
                name: "Кафедра социологии и политологии (СП)",
                id: 51,
                shortName: "СП"
            },
            {
                name: "Кафедра иностранных языков (ИНЯЗ)",
                id: 52,
                shortName: "ИНЯЗ"
            },
            {
                name: "Кафедра истории культуры, государства и права (ИКГП)",
                id: 53,
                shortName: "ИКГП"
            }],
        id: 48
    },
    {
        name: "Кафедра русского языка (РЯ)",
        departments: null,
        id: 54,
        shortName: "РЯ"
    }
];