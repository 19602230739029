import React, {useState, useEffect, useRef} from "react";
import Menu from "./Menu";

export default function MenuWrapper(props) {

    const [menuState, setMenu] = useState(document.documentElement.clientWidth > 1366); //false - collapsed, true - opened
    const wrapperRef = useRef(null);

    useEffect(() => {
        if (menuState === false) {
            return;
        }

        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                handleMenuCollapse();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuState]);

    function handleSetMenu(data) {
        if (menuState !== data && document.documentElement.clientWidth <= 1366) {
            setMenu(data);
        }
    }

    function handleMenuExpand(state) {
        handleSetMenu(true);
    }

    function handleMenuCollapse(state) {
        handleSetMenu(false);

    }

    return <div ref={wrapperRef} className='menu-wrapper' id={menuState ? 'menu-open' : 'menu-close'}
                onClick={handleMenuExpand}>
        <Menu onclick={handleMenuCollapse} isExpanded={menuState}/>
    </div>
}