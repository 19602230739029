import CardTable from '../CardTable/CardTable';
import './LineTable.css'
import React from 'react';

export const locationDic = {
    '8:00': {name: 'first', index: 0, range: "8:00 - 9:25"},
    '9:40': {name: 'middle', index: 1,range:"9:40 - 11:05"},
    '11:35': {name: 'middle', index: 2,range:"11:35 - 13:00"},
    '13:45': {name: 'middle', index: 3,range:"13:45 - 15:10"},
    '15:40': {name: 'middle', index: 4,range:"15:40  - 17:05"},
    '17:20': {name: 'middle', index: 5,range:"17:20 - 18:45"},
    '19:00': {name: 'middle', index: 6,range:"19:00 - 20:25"},
    '20:35': {name: 'last', index: 6,range:"20:35 - 22:00"},
}

export default function LineTable(props) {
    const isTeacher = props.isTeacher;
    const time = props.time;
    const timeData = locationDic[time];
    const json = props.json?.filter((value) => value.TimeID === timeData.index);
    const isWeekTable = props.isWeekTable;
    const dayWeekIndex = props.dayWeekIndex;


    const nameTimeLine = timeData.name + '-line';
    const nameTime = timeData.name + '-time time';
    const cards = [];

    for (let index = (isWeekTable ? 0 : dayWeekIndex); index <= (isWeekTable ? 5 : dayWeekIndex); index++) {
        const lessons = json?.filter(value => value.DayID === index);
        if (lessons === undefined || lessons.count === 0 || lessons[0] === undefined) {
            cards.push(
                <td key={nameTimeLine + '-' + index} id={nameTimeLine + '-' + index} className={nameTimeLine}>
                    <CardTable/>
                </td>
            );
            continue;
        }
        let type = lessons[0].type;
        let subject = lessons[0].Name;
        let addition = lessons[0].Addition;
        let teacher = isTeacher ? lessons.map((value) => 'гр. ' + value.GroupStr) : lessons[0].TeacherStr;
        let classNum = lessons[0].LectureHall;
        cards.push(
            <td key={nameTimeLine + '-' + index} id={nameTimeLine + '-' + index} className={nameTimeLine}>
                <CardTable subject={subject} addition={addition} teacher={teacher} classNum={classNum} type={type} isTeacher={isTeacher} time = {time}/>
            </td>
        );
    }


    return <>
        <td className={nameTime}><h2>{time}</h2></td>
        {cards}
    </>;
}