import logo from "./Images/logo_etu.webp";
import './Menu.css'
//import {daysName, getWeekNumber, monthName, weekName} from "../Helpers/Date";
import React, {useEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import MenuItem from "./MenuItem/MenuItem";
import {dataTypes, setLocal, getLocal} from "../Helpers/LocalStorage";
import {helperRedirect} from "../Helpers/Redirect";
//import Weather from "../Weather/Weather";


export default function Menu(props) {
    const [toggleValue, setToggleValue] = useState(getLocal(dataTypes.Theme) === 1);

    function handleThemeToggle(event){
        setToggleValue(!toggleValue);
        setLocal(dataTypes.Theme, toggleValue ? 0 : 1);
    }

    if(toggleValue)
        document.documentElement.setAttribute('data-theme','dark');
    else
        document.documentElement.setAttribute('data-theme','light');

    //const nowDate = new Date();
    return <nav className="nav-list">
        <i className="material-icons" id={props.isExpanded ? 'hide' : 'view-headline'}>{'view_headline'}</i>
        <div className='logo-container'>
            <img src={logo} alt='Логотип СПбГЭТУ "ЛЭТИ"' id={props.isExpanded ? null : 'hide'} className="logo-img"/>
        </div>

        <NavLink className={'list-elem schedule'} id={props.isExpanded ? null : 'hide'} activeClassName="select"
                 to='/schedule' onClick={props.onclick}>
            <MenuItem icon='date_range' text='Расписание'/>
        </NavLink>
        <NavLink className={'list-elem mySchedule'} id={props.isExpanded ? null : 'hide'} activeClassName="select"
                 to='/mySchedule' onClick={props.onclick}>
            <MenuItem icon='account_box' text='Моё расписание'/>
        </NavLink>
        {/*<NavLink className={'list-elem map'} id = {props.shouldHide? 'hide' : null} activeClassName="select"
         to='/map' onClick={handlerOnClick}>
            <MenuItem icon='map' text='Карта университета'/>
        </NavLink>*/}
        <NavLink className={'list-elem lk'} id={props.isExpanded ? null : 'hide'} activeClassName="select" to='/lk'
                 onClick={props.onclick}>
            <MenuItem icon='person' text='Личный кабинет'/>
        </NavLink>
        <NavLink className={'list-elem listOfTeachers'} id={props.isExpanded ? null : 'hide'} activeClassName="select"
                 to='/listOfTeachers' onClick={props.onclick}>
            <MenuItem icon='view_list' text='Список преподавателей'/>
        </NavLink>

        <div className='toggle-container' hidden={!props.isExpanded}>
            <i className="toggle--on material-icons" id="search-icon">{'brightness_high'}</i>
            <input  type="checkbox" defaultChecked={toggleValue} id="id-name--1" className="switch-input" onInput={handleThemeToggle}/>
            <label htmlFor="id-name--1" className="switch-label"/>
            <i className="toggle--off material-icons" id="search-icon">{'brightness_low'}</i>
        </div>
        {/*<section className="date-section">
            <ul className="date-elem">
                <p className="date">{daysName[nowDate.getDay()]}, {nowDate.getDate()} {monthName[nowDate.getMonth()]}</p>
            </ul>

            <ul className="week-elem">
                <p className="week-number">{weekName[getWeekNumber(nowDate) % 2]} неделя</p>
            </ul>
        </section>
        <Weather/>*/
        }
    </nav>
        ;
}