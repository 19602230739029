import React from 'react';
import './Footer.css'
import {dateNumConvert} from "../Helpers/Date";

export default function Footer(props){
    let isWeekTable = props.isWeekTable;
    const dayOfWeek = [6,0,1,2,3,4,5]
    let date = new Date(props.selectedDate);
    let weekStart = new Date(date);
    weekStart.setDate(date.getDate() - dayOfWeek[date.getDay()]);
    let weekEnd = new Date(date);
    weekEnd.setDate(date.getDate() + 6 - dayOfWeek[date.getDay()]);

    let weekLabel = <h2 className="date-week">Неделя {dateNumConvert(weekStart)} - {dateNumConvert(weekEnd)}</h2>;
    let dayLabel = <h2 className="date-week">День {dateNumConvert(date)}</h2>;

    return (
        <footer className="change-week">
            <p className="underline-week"/>
            <section className="change-week-section">
                <i className="material-icons" id = "back-icon" onClick={props.onBackward}>{'keyboard_arrow_left'}</i>
                {isWeekTable ? weekLabel : dayLabel}
                <i className="material-icons" id = "next-icon" onClick={props.onForward}>{'keyboard_arrow_right'}</i>
            </section>
        </footer>
    )
}