import React, {useState} from "react";
import './TeacherCard.css';
import {helperRedirect} from "../../Helpers/Redirect";
import {dataTypes, setLocal} from "../../Helpers/LocalStorage";
import teachers_photo from "./photo.png";


export default function TeacherCard(props) {
    const data = props.data;
    const hasSchedule = data["hasSchedule"];
    const [cardClick, setClick] = useState(false);

    function handleOnClick(event) {
        setLocal(dataTypes.SelectedSchedule, {'item': data.FullName, "entity": "teacher"});
        helperRedirect('/schedule');
    }

    function cardOnClick(event)
    {
        setClick(!cardClick);
    }

    let arrowStyle = null;

    const scheduleButton = hasSchedule &&
        <button onClick={handleOnClick} className={"button-schedule"}>Расписание</button>;

    let positionHeld;

    if (data["PositionHeld"] !== null) {
        positionHeld = <div>
            <div className='card-text card-text-bold text-held'>{'Должность:'}</div>
            <div className='card-text li-held'>{data["PositionHeld"]}</div>
        </div>;
    } else {
        positionHeld = <div>
            <div className='card-text card-text-bold text-held'>&nbsp;</div>
            <div className='card-text li-held'>&nbsp;</div>
        </div>;
    }

    let disciplines;

    if(data["Disciplines"] !== null)
    {
        disciplines = <>
        <div className='card-text card-text-bold text-disciplines'>{'Преподаваемые дисциплины:'}</div>
        <ul className={"ul-disciplines"}>{Array.prototype.slice.call(data["Disciplines"]).map((value) => <li className={"li-disciplines"}><h3 className='card-text li-text'>{value}</h3></li>)}</ul>
    </>;}
    else{
        disciplines = null;
        arrowStyle = {
            display: "none"
        };
    }


    let email;

    if(data["Email"] !== null)
    {
        email = <div className='card-text card-email'>{"•   " + data["Email"] + "   •"}</div>;
    }
    else
    {
        email = <div className='card-text card-email'>&nbsp;</div>;
    }


    if(props.showSearch)
    {
        return (
            <div className="teacher-card-search">
                <i className={"material-icons"} id = {"close-icon"} onClick={() => {props.onclick()}}>{'close'}</i>
                <div className='card-title'>{data.FullName}</div>
                <div className={"contacts"}>
                    {email}
                </div>
                <div className={"underline-teacher"}> </div>
                <div className={"faculty-department"}>{data["Faculty"] + ", " + data["Department"]}</div>
                {positionHeld}
                {disciplines}
                {scheduleButton}
            </div>
        )
    }else{
        return (
            <div className="teacher-card" onClick = {cardOnClick} id = {cardClick && data["Disciplines"]!= null ? "cardIsClicked": null}>
                <div className='card-title'>{data.FullName}</div>
                <div className={"contacts"}>
                    {email}
                </div>
                <div className={"underline-teacher"}> </div>
                {positionHeld}
                <i className={"material-icons"} id = {"show-more"} style = {arrowStyle}>{'keyboard_arrow_down'}</i>
                {disciplines}
                {scheduleButton}
            </div>
        )
    }

}