import React from 'react';
import './CardInfo.css';
import {locationDic} from "../LineTable/LineTable";

export default function CardInfo(props) {
    let typeSubject = {
        0: "Лекция",
        1: "Практика",
        2: "Лабораторная работа",
        3: " ",
    }



    if (props.propsParent.subject === undefined) {

        return null;

    } else {
        const isTeacher = props.propsParent.isTeacher;
        const type = props.propsParent.type;
        const subject = props.propsParent.subject;
        const teacher = props.propsParent.teacher.slice(0, 7);
        const addition = props.propsParent.addition;
        const classNum = props.propsParent.classNum ?? '';
        const time = props.time;


        const color = props.color;
        const underlineColor = props.underlineColor;
        return (<>
                <div className="card-background-container" id = {time === "15:35" || time === "17:25" || time === "19:15"? "bottom-line": null}>
                    <div className="card-container" /*style={{backgroundColor: color}}*/>
                        <div className={'time-subject'}>{locationDic[time].range}</div>
                        <ul>{subject.map((value) => <li><h2 className='subject'>{value.Item2}</h2></li>)}</ul>
                        <ul>{subject.map((value) => <li><h3 className='card-text'>{value.Item1}</h3></li>)}</ul>

                        {
                            teacher.length === 0 ? null :
                                <><h3 className='card-text'>
                                    {isTeacher ? 'Группы:' : 'Преподаватель:'}
                                </h3>
                                    <ul>{teacher.map((value) => <li><h3 className='name'>{value}</h3></li>)}</ul>
                                </>
                        }
                        {
                            classNum.length === 0 ? null :
                                <><h3 className='card-text'>Аудитория:</h3>
                                    <h3 className='class-number'>{classNum}</h3>
                                </>
                        }
                        {
                            addition.length === 0 ? null :
                                <><h3 className='card-text'>Доп-информация:</h3>
                                    <h3 className='addition-info'>{addition}</h3>
                                </>
                        }
                        <h3 className='card-text subject-type' style = {{backgroundColor: color}}>{typeSubject[type]}</h3>
                        <p style={{backgroundColor: underlineColor}} className='underline-subject'/>
                    </div>
                </div>
            </>
        );
    }

}