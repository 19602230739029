import React, {useEffect, useState} from 'react'
import isAndroid from "../Helpers/IsAndroid";
import {Helmet} from "react-helmet";

export default function HelmetWrapper() {
    const orientation = {
        'landscape': [undefined, undefined],
        'portrait': [undefined, undefined]
    };

    const [height, setHeight] = useState('device-height');

    useEffect(() => {
        if (!isAndroid())
         return;

        function onOrientationChange() {
            let vh;
            //console.log(window.screen.orientation.angle);
            switch (window.screen.orientation.angle) {
                case -90:
                case 90:
                    if (orientation['landscape'][0] === orientation['landscape'][1] || orientation['portrait'][0] === orientation['landscape'][0]  || orientation['portrait'][1] === orientation['landscape'][1]) {
                        orientation['landscape'] = [window.innerHeight, window.innerWidth];
                    }
                    vh = orientation['landscape'][0] * 0.01;
                    setHeight(orientation['landscape'][0]);
                    break;
                default:
                    if (orientation['portrait'][0] === orientation['portrait'][1] || orientation['portrait'][0] === orientation['landscape'][0] || orientation['portrait'][1] === orientation['landscape'][1]) {
                        orientation['portrait'] = [window.innerHeight, window.innerWidth];
                    }
                    vh = orientation['portrait'][0] * 0.01;
                    setHeight(orientation['portrait'][0]);
                    break;
            }
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
        onOrientationChange();
        window.addEventListener('resize', onOrientationChange);
        return () =>
            window.removeEventListener('resize', onOrientationChange);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return <Helmet>
        <title>Расписание</title>
        <meta content={'height=' + height + ', width=device-width, initial-scale=1.0, maximum-scale=1.0,' +
        ' user-scalable=0'}
              name='viewport'/>
    </Helmet>;
}