import React, {useRef} from 'react';

export const typeSwipe = {
    onSwiped: 0,          // Fired after any swipe
    onSwipedLeft: 1,      // Fired after LEFT swipe
    onSwipedRight: 2,     // Fired after RIGHT swipe
    onSwipedUp: 3,        // Fired after UP swipe
    onSwipedDown: 4,      // Fired after DOWN swipe
}

export function Swipe(props) {
    const length = props.length ?? 1;
    const element = useRef(null);

    let xDown = null;
    let yDown = null;

    function handleTouchStart(event) {
        xDown = event.touches[0].clientX;
        yDown = event.touches[0].clientY;
    }

    function onLeft(event) {
        props.onSwipe(typeSwipe.onSwipedLeft);
    }

    function onRight(event) {
        props.onSwipe(typeSwipe.onSwipedRight);
    }

    function onUp(event) {
        props.onSwipe(typeSwipe.onSwipedUp);
    }

    function onDown(event) {
        props.onSwipe(typeSwipe.onSwipedDown);
    }

    function handleTouchMove(event) {
        if (!xDown || !yDown) {
            return;
        }

        const xUp = event.touches[0].clientX;
        const yUp = event.touches[0].clientY;

        const xDiff = xDown - xUp;
        const yDiff = yDown - yUp;
        if (Math.abs(xDiff) > length || Math.abs(yDiff) > length)
            if (Math.abs(xDiff) > Math.abs(yDiff)) { // Most significant.
                if (xDiff > 0) {
                    onLeft();
                } else {
                    onRight();
                }
            } else {
                if (yDiff > 0) {
                    onUp();
                } else {
                    onDown();
                }
            }

        // Reset values.
        xDown = null;
        yDown = null;
    }

    //element.addEventListener('touchmove', handleTouchMove);
    //element.addEventListener('touchstart', handleTouchStart);


    return (<div className={props.className} onTouchMove={handleTouchMove} onTouchStart={handleTouchStart} ref={element}
                 style={props.styles}>{props.children} </div>);
}