export function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));

    // Calculate full weeks to nearest Thursday
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
}

export function dateNumConvert(date)
{
    // Adding zeros to date
    let result1 = date.getDate().toString();
    result1 = result1.length < 2 ? '0' + result1 : result1;

    let result2 = (date.getMonth()+ 1).toString();
    result2 = result2.length < 2 ? '0' + result2 : result2;

    // Sobaki sliplis
    return result1 + '.' + result2;
}

export const daysName = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];
export const weekName = ['Первая', 'Вторая']
export const monthName = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля",
    "августа", "сентября", "октября", "ноября", "декабря"];