import React, {useEffect, useState} from 'react';
import './Search.css'
import {API_URL} from "../../App";


export default function Search(props) {
    const searchType = props.type;
    const placeHolder = searchType === 'table' ? 'Найти расписание' : 'Найти преподавателя';
    const [data, setData] = useState({'items': [], 'entity': undefined});
    const [query, setQuery] = useState(undefined);

    function setUndefinedData() {
        setData({'items': [], 'entity': undefined});
    }

    function handleSelect(event) {
        props.onSelect({'item': event.target.text, 'entity': data.entity});
        document.getElementById("field-search-form").reset();
        setUndefinedData();
    }

    function handleChange(event) {
        setQuery(event.target.value);
    }

    function handleKeyPress(event) {
        if (event.keyCode === 13) {
            if (data.items.length > 0)
                props.onSelect({'item': data.items[0], 'entity': data.entity});
        }
        setUndefinedData();
    }

    useEffect(() => {
        if (query !== undefined && query.length > 0) {

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': "*",
                },
                body: JSON.stringify({
                    "query": query,
                    "req_type": searchType,
                    "req_act": "search",
                }),
            };
            fetch(API_URL, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        setData(result.data);
                    },
                    (error) => {
                        console.log(error);
                    }
                )
        } else {
            setUndefinedData();
        }
    }, [query]);

    return (<>
        <form className='search-wrapper' id='field-search-form' htmlFor='field-search'>
            <i className="material-icons" id="search-icon">{'search'}</i>
            <input id='field-search' className='field-search' size={placeHolder.length} autoComplete={'off'} type="text"
                   placeholder={placeHolder}
                   onInput={handleChange} onKeyDown={handleKeyPress}/>
            <p className="underline-search"/>
        </form>
        <div className="dropdown-content">
            {
                data.items.map((item, index) => (
                <a key={"search-item-" + index} id={"search-item-" + index} onClick={handleSelect}>
                    {item}
                </a>
            ))}
        </div>
    </>);
}