export function getLocal(key) {
    return JSON.parse(localStorage.getItem(key));
}

export function setLocal(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export const dataTypes = {
    SelectedDate: 'SelectedDate',
    DefaultSchedule: 'DefaultSchedule',
    SelectedSchedule: 'SelectedSchedule',
    Theme:'Theme',
}