import React, {useState} from 'react';
import './CardTable.css';
import CardInfo from "../CardInfo/CardInfo";
import {ShortenName} from "../../Helpers/ShortenName";

export default function CardTable(props) {

    const [cardInfo, setCardInfo] = useState(null);

    let styleCardBackColor = {
        0: "rgba(172, 219, 245, 0.4)",
        1: "rgba(147, 200, 92, 0.4)",
        2: "rgba(245, 207, 172, 0.4)",
        3: "rgba(128, 128, 128, 0.4)",
    }
    let styleUnderLnBackColor = {
        0: "rgba(172, 219, 245, 1)",
        1: "rgba(147, 200, 92, 1)",
        2: "rgba(245, 207, 172, 1)",
        3: "rgba(128, 128, 128, 1)",
    }
    let section;
    if (props.subject === undefined || props.teacher === undefined) {

        section = <>
            <section className='card-subject' style={{backgroundColor: styleCardBackColor[props.type]}}/>
        </>;

    } else {
        const type = props.type;
        const subject = props.subject.length === 0 ? ['\u00A0'] : props.subject;
        const teacher = props.teacher.length === 0 ? ['\u00A0'] : props.teacher.slice(0, 2);
        const addition = props.addition.length === 0 ? ['\u00A0'] : props.addition;
        const classNum = props.classNum ?? '\u00A0';

        function handleOnClick(event) {
            setCardInfo(<CardInfo color={styleCardBackColor[type]} underlineColor={styleUnderLnBackColor[type]}  propsParent={props} time = {props.time}/>);
        }

        function handleOnMouseLeave(event) {
            setCardInfo(null);
        }

        section = <>
            <div onClick={handleOnClick} onMouseLeave={handleOnMouseLeave}
                 style={{backgroundColor: styleCardBackColor[type]}}
                 className='card-subject filled-card'>
                {cardInfo}
                <ul>{subject.map((value,index) => <li key={`card-table-subject-${index}`}><h2 className='subject'>{value.Item2}</h2></li>)}</ul>
                <h3 className='addition-info'>{addition}</h3>
                <div className='subject-data'>
                    <ul>{teacher.map((value,index) => <li key={`card-table-name-${index}`}><h3 className='name'>{ShortenName(value)}</h3></li>)}</ul>
                    <h3 className='class-number'>{classNum}</h3>
                </div>
                <p style={{backgroundColor: styleUnderLnBackColor[type]}} className='underline-subject'/>
            </div>
        </>
        ;
    }
    return section;
}