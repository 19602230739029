import React, {useState} from 'react';
import './StartSearch.css';
import Search from "../Search/Search";
import image from "./IMG_0140.webp";
import cutCorner from "./untitled.svg";
import OnKeyboardEvent from "../Helpers/OnKeyboardEvent";


export default function StartSearch(props) {

    const searchType = props.type;
    const searchInfo = searchType === 'table' ? 'Найдите расписание по номеру группы или ФИО преподавателя' : 'Найдите преподавателя по ФИО или перечень преподавателей по направлению';
    const header = searchType === 'table' ? 'Расписание СПбГЭТУ «ЛЭТИ»' : 'Преподаватели СПбГЭТУ «ЛЭТИ»';
    return <>
        <img src={cutCorner} className={"cut-corner"} alt='Изображение на фоне'/>
        <section className={'search-box'}>
            <OnKeyboardEvent>
                <img src={image} className={'start-image'} alt='Изображение здания уничверситета СПбГЭТУ "ЛЭТИ"'/>
            </OnKeyboardEvent>
            <div className="info-block">
                <h1 className={"search-header"}>{header}</h1>
                <h2 className={"search-info"}>{searchInfo}</h2>
                <div className={"search-block"}>
                    <Search onSelect={props.onSelect} type={searchType}/>
                </div>
            </div>
        </section>
    </>;
}